// src\logic\movements\MovementsLogic.jsx

import { useQuery } from "@tanstack/react-query";
import { getAllMovements } from "../../api/MovementService.jsx";
import { MovementsColumns } from "./MovementsColumns.jsx";
import { LoaderIconUtils } from "../../utils/LoaderIconUtils.jsx";
// import { useColumnSearchProps } from "../../utils/useColumnSearchProps.jsx";
import { TablesComponent } from "../../components/TablesComponent.jsx";
import { useEffect, useState } from "react";

// src\logic\movements\MovementsLogic.jsx

export const MovementsLogic = () => {
  console.log("MovementsLogic.jsx");
  const [clearFiltersRefs, setClearFiltersRefs] = useState([]);
  const [clearFilters, setClearFilters] = useState(false);

  useEffect(() => {
    if (typeof setClearFiltersRefs !== "function") {
      console.error(
        "setClearFiltersRefs no es una función",
        setClearFiltersRefs
      );
    }
  }, [setClearFiltersRefs]);

  const {
    data: movements,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["movements"],
    queryFn: getAllMovements,
  });

  // const typeSearchProps = useColumnSearchProps(
  //   "type",
  //   "movement",
  //   "Tipo",
  //   null,
  //   clearFilters,
  //   setClearFiltersRefs,
  //   0
  // );
  // const actionSearchProps = useColumnSearchProps(
  //   "action",
  //   "movement",
  //   "Accion",
  //   null,
  //   clearFilters,
  //   setClearFiltersRefs,
  //   1
  // );
  // const moduleSearchProps = useColumnSearchProps(
  //   "module",
  //   "movement",
  //   "Modulo",
  //   null,
  //   clearFilters,
  //   setClearFiltersRefs,
  //   2
  // );
  // const responsibleNameSearchProps = useColumnSearchProps(
  //   "responsible_name",
  //   "athlete",
  //   "Responsable",
  //   null,
  //   clearFilters,
  //   setClearFiltersRefs,
  //   3
  // );
  // const modelFieldHelperSearchProps = useColumnSearchProps(
  //   "model_field_helper",
  //   "athlete",
  //   "Referencia",
  //   null,
  //   clearFilters,
  //   setClearFiltersRefs,
  //   4
  // );
  // // En MovementsLogic.jsx, justo después de definir las props
  // console.log("typeSearchProps: ", typeSearchProps);
  // console.log("actionSearchProps: ", actionSearchProps);
  // console.log("moduleSearchProps: ", moduleSearchProps);
  // console.log("responsibleNameSearchProps: ", responsibleNameSearchProps);
  // console.log("modelFieldHelperSearchProps: ", modelFieldHelperSearchProps);

  useEffect(() => {
    if (clearFilters) {
      clearFiltersRefs.forEach(({ clearFilters, confirm }) => {
        if (clearFilters && confirm) {
          clearFilters();
          confirm();
        }
      });
      setClearFilters(false);
    }
  }, [clearFilters, clearFiltersRefs, setClearFilters]);

  if (error) return <div>Error: {error.message}</div>;
  if (isLoading) return <LoaderIconUtils isLoading={true} />;

  const columns = MovementsColumns();

  console.log("[MovementsLogic]->columns: ", columns);

  return (
    <TablesComponent data={movements} loading={isLoading} columns={columns} />
  );
};
