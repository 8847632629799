// src\components\StatisticCardComponent.jsx

import { Card, Statistic } from "antd";
import PropTypes from "prop-types";

export const StatisticCard = ({ statistics, backgroundClass }) => {
  // console.log('statistics: ', statistics)
  return (
    <Card className={`mt-3 shadow-md ${backgroundClass}`}>
      {statistics.map((stat, index) => (
        <Statistic
          key={index}
          valueStyle={{
            fontSize: stat.fontSize,
            fontWeight: stat.fontWeight,
          }} // Asegúrate de pasar fontSize correctamente
          title={<strong className={"font-black"}>{stat.title}</strong>}
          value={stat.value}
          prefix={stat.prefix}
        />
      ))}
    </Card>
  );
};

StatisticCard.propTypes = {
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      title:  PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node, // Permite elementos JSX
      ]).isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      prefix: PropTypes.element,
      fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Asegúrate de que esto se maneje adecuadamente en tu estructura de datos o en el componente padre
    })
  ).isRequired,
  backgroundClass: PropTypes.string,
};
