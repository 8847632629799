// src\logic\reports\athletesEnriched\AthletesEnrichedToExcel.jsx

import * as XLSX from "xlsx";
import dayjs from "dayjs";
import moment from "moment/moment";
import "moment/locale/es-mx";
moment.locale("es-mx");
// Función auxiliar para acceso seguro a propiedades anidadas
const safeAccess = (obj, path, defaultValue = "") => {
  return path
    .split(".")
    .reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : defaultValue),
      obj
    );
};

export const exportAthletesToExcel = (athletesEnriched) => {
  console.log("Iniciando exportación de atletas a Excel");
  console.log("Atletas recibidos:", athletesEnriched);
  // Transformar los datos para que cada fila corresponda a un recibo
  const transformedData = athletesEnriched.flatMap((athlete) => {
    console.log("Procesando atleta:", athlete.name);
    const payments = Array.isArray(athlete.payments) ? athlete.payments : [];
    console.log("Pagos del atleta:", athlete.name, payments);
    return payments.map((payment) => {
      console.log(
        "Procesando pago para atleta:",
        athlete.name,
        "| Pago ID:",
        payment._id
      );
      return {
        Matrícula: athlete.tuition,
        Nombre: athlete.name,
        Estatus: athlete.status ? "Activo" : "Inactivo",
        "Está Inscrito": athlete.is_inscribed ? "Sí" : "No",
        "Nombre del Paquete del Recibo": safeAccess(
          payment,
          "receipt_id.receipt_package_name"
        ),
        "Monto del Recibo": payment.amount || "N/A",
        "Estatus del Recibo": safeAccess(payment, "receipt_id.receipt_status"),
        "Descripción del Recibo": safeAccess(
          payment,
          "receipt_id.receipt_description"
        ),
        "Método de Pago": payment.payment_method || "N/A",
        "Fecha de Creación del Pago": payment.created_at
          ? dayjs(payment.created_at).format("DD/MM/YYYY")
          : "N/A",
        "Fecha en que se pagó": safeAccess(payment, "receipt_id.updated_at")
          ? dayjs(safeAccess(payment, "receipt_id.updated_at")).format(
              "DD/MM/YYYY"
            )
          : "N/A",
      };
    });
  });

  console.log("Datos transformados para exportación:", transformedData);

  // Verificar si los datos transformados están vacíos
  if (transformedData.length === 0) {
    console.warn(
      "No se encontraron datos para exportar. Verifique los pagos de los atletas."
    );
  }

  // Crear la hoja de trabajo con los datos transformados
  const worksheet = XLSX.utils.json_to_sheet(transformedData.reverse());

  // Añadir la fila de encabezado con nombres en español
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        "Matrícula",
        "Nombre",
        "Estatus",
        "Está Inscrito",
        "Nombre del Paquete del Recibo",
        "Monto del Recibo",
        "Estatus del Recibo",
        "Descripción del Recibo",
        "Método de Pago",
        "Fecha de Creación del Pago",
        "Fecha en que se pagó",
      ],
    ],
    { origin: "A1" }
  );

  // Ajustar el ancho de las columnas
  worksheet["!cols"] = [
    { wch: 10 }, // Matrícula
    { wch: 30 }, // Nombre
    { wch: 10 }, // Estatus
    { wch: 13 }, // Está Inscrito
    { wch: 30 }, // Nombre del Paquete del Recibo
    { wch: 15 }, // Monto del Recibo
    { wch: 18 }, // Estatus del Recibo
    { wch: 30 }, // Descripción del Recibo
    { wch: 20 }, // Método de Pago
    { wch: 25 }, // Fecha de Creación del Pago
    { wch: 25 }, // Fecha en que se pagó
  ];

  console.log("Creando hoja de trabajo para exportación");

  // Aplicar filtro a las cabeceras
  worksheet["!autofilter"] = { ref: `A1:K${transformedData.length + 1}` };

  // Crear el libro de trabajo y añadir la hoja de trabajo
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte de Ingresos");

  // Escribir el archivo Excel
  console.log("Escribiendo archivo Excel");
  XLSX.writeFile(
    workbook,
    `Reporte_de_ingresos_${dayjs().format("YYYYMMDD_HHmmss")}.xlsx`
  );
};

export const exporPaymentsToExcel = (
  athletesEnrichedWithCategorizedPayments
) => {
  console.log("Iniciando exportación de pagos a Excel");
  console.log(
    "Atletas recibidos para exportación de pagos:",
    athletesEnrichedWithCategorizedPayments
  );
  // Transformar los datos para que cada fila corresponda a un recibo
  const transformedData = athletesEnrichedWithCategorizedPayments.flatMap(
    (athlete) => {
      console.log("Procesando atleta:", athlete.name);
      const paymentsByMonth = athlete.payments_by_month || {};
      const allPayments = [
        ...(paymentsByMonth.previousMonth || []),
        ...(paymentsByMonth.currentMonth || []),
        ...(paymentsByMonth.nextMonth || []),
      ];

      console.log("Pagos por mes del atleta:", athlete.name, allPayments);

      if (allPayments.length === 0) {
        console.warn(
          "El atleta no tiene pagos por mes registrados:",
          athlete.name
        );
      }

      return allPayments.map((payment) => {
        console.log(
          "Procesando pago para atleta:",
          athlete.name,
          "| Mes:",
          moment(payment.period_month).format("MMMM")
        );
        return {
          Matrícula: athlete.tuition,
          Nombre: athlete.name,
          Membresía: `${safeAccess(payment, "receipt_id.receipt_package_name")} (${safeAccess(payment, "receipt_id.receipt_type")})`,
          Tutor: payment.user_name || "N/A",
          Estatus: athlete.status ? "Activo" : "Inactivo",
          Mes: moment(payment.period_month).format("MMMM") || "Sin mes",
          "Monto Pagado": payment.amount_balance_updated || 0,
          "Monto Pendiente":
            payment.amount - payment.amount_balance_updated || 0,
          "Estatus del Recibo": payment.status || "N/A",
        };
      });
    }
  );

  console.log("Datos transformados para exportación:", transformedData);

  // Verificar si los datos transformados están vacíos
  if (transformedData.length === 0) {
    console.warn(
      "No se encontraron datos para exportar. Verifique los pagos de los atletas."
    );
  }

  // Crear la hoja de trabajo con los datos transformados
  const worksheet = XLSX.utils.json_to_sheet(transformedData.reverse());

  // Añadir la fila de encabezado con nombres en español
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        "Matrícula",
        "Nombre",
        "Membresía",
        "Tutor",
        "Estatus",
        "Mes",
        "Monto Pagado",
        "Monto Pendiente",
        "Estatus del Recibo",
      ],
    ],
    { origin: "A1" }
  );

  // Ajustar el ancho de las columnas
  worksheet["!cols"] = [
    { wch: 20 }, // Matrícula
    { wch: 30 }, // Nombre
    { wch: 30 }, // Membresía
    { wch: 25 }, // Tutor
    { wch: 10 }, // Estatus
    { wch: 15 }, // Mes
    { wch: 15 }, // Monto Pagado
    { wch: 15 }, // Monto Pendiente
    { wch: 20 }, // Estatus del Recibo
  ];

  console.log("Creando hoja de trabajo para exportación");

  // Aplicar filtro a las cabeceras
  worksheet["!autofilter"] = { ref: `A1:I${transformedData.length + 1}` };

  // Crear el libro de trabajo y añadir la hoja de trabajo
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(
    workbook,
    worksheet,
    "Reporte de Estatus de Pago"
  );

  // Escribir el archivo Excel
  console.log("Escribiendo archivo Excel");
  XLSX.writeFile(
    workbook,
    `reporte_estatus_pago_${dayjs().format("YYYYMMDD_HHmmss")}.xlsx`
  );
};
