// src\logic\discounts\DiscountsEnrichedFunction.jsx

export const enrichDiscounts = (discounts, packagesData, athletesData) => {
  if (!discounts || !packagesData || !athletesData) return [];

  return discounts.map((discount) => {
    // Encontrar los datos de la membresía correspondiente
    const membershipData = packagesData.find(
      (membership) => membership._id === discount.product_id
    );

    // Enriquecer los datos de los atletas asociados
    const athletesNewData = discount.athletes?.length
      ? athletesData.filter((athleteObject) =>
          discount.athletes.some((athlete) => athlete === athleteObject._id)
        )
      : [];

    return {
      ...discount,
      membership: membershipData || null, // Asegurar valor por defecto
      athletes: athletesNewData, // Valor predeterminado ya gestionado como []
    };
  });
};
