// src\utils\FieldsComposerUtils.jsx

import dayjs from "dayjs";

export const prepareRecord = (record) => {
  Object.entries(record).forEach(([key, value]) => {
    if (
      Array.isArray(value) &&
      value.length > 0 &&
      typeof value[0] === "object" &&
      value[0]._id
    ) {
      record[key] = value.map((item) => item._id);
    } else if (
      Array.isArray(value) &&
      value.length > 0 &&
      typeof value[0] === "object" &&
      value[0].id
    ) {
      record[key] = value.map((item) => item.id);
    } else if (
      Array.isArray(value) &&
      value.length > 0 &&
      typeof value[0] === "object" &&
      value[0].name
    ) {
      record[key] = value.map((item) => item.name);
    } else if (
      Array.isArray(value) &&
      value.length > 0 &&
      typeof value[0] === "object" &&
      value[0].title
    ) {
      record[key] = value.map((item) => item.title);
    } else if (
      Array.isArray(value) &&
      value.length > 0 &&
      typeof value[0] === "object" &&
      value[0].description
    ) {
      record[key] = value.map((item) => item.description);
      record[key] = value.map((item) => item.id);
    } else if (value instanceof Date || typeof value === "string") {
      const dateAttempt = dayjs(value);
      if (dateAttempt.isValid()) {
        record[key] = dateAttempt;
      }
    }
  });

  // Asignar discount_type en función de los valores presentes
  if (record.discount_amount !== null && record.discount_amount !== undefined) {
    record.discount_type = "amount";
  } else if (record.discount_percentage !== null && record.discount_percentage !== undefined) {
    record.discount_type = "percentage";
  }

  return record;
};
